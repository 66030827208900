.form-feedback-invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--bs-form-invalid-color);
}


.hover-select {
    &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 10px $primary;
    }
}

.step-bar {
    padding: 30px;
    display: flex;
    justify-content: space-around;
    align-content: center;
    width: 100%;

    .bar {
        width: 30%;
        height: 3px;
        background-color: darkgray;
        margin-top: 18px;
    }

    .bar-complete {
        width: 30%;
        height: 3px;
        background-color: $primary;
        margin-top: 18px;
    }

    .number {
        color: white;
        background-color: darkgray;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        border: 1px;
        font-size: medium;
        font-weight: 400;
    }

    .active {
        .number {
            background-color: $primary; 
            box-shadow: 0px 0px 10px $primary;
        }
        
        p {
            font-weight: bold;
        }
    }

    .complete {
        .number {
            background-color: $primary; 
        }

        p {
            font-weight: bold; 
        }
    }

    .step {
        text-align: center;
    }
}

.nav-buttons {
    display: flex;
    width: 100%;
    justify-content: end;

    Button {
        margin-left: 10px;
    }
}

.profile-user-wid{
    margin-top: -26px;
}

.animated-progess {
    position: relative;
    .progress {
        border-radius: 10px;
    }
    .progress-bar {
        position: relative;
        animation: animate-positive 2s;
    }
}
@keyframes animate-positive {
    0% {
        width: 0;
    }
}
.progress-sm {
    height: 5px;
}
.progress-md {
    height: 8px;
}
.progress-lg {
    height: 12px;
}
.progress-xl {
    height: 20px;
}

#react-select-3-listbox {
    // border: solid 1px red;
    // height: 500px !important;
    // z-index: 999;
    position: absolute;
    margin-bottom: 50px;
}

// #react-select-3-input {
//     border: solid 1px green;
// }

.bot-features-height {
    min-height: 145px;
}

@media (max-width: 768px) {
    .bot-features-height {
        min-height: auto;
    }
}

@media (max-width: 1580px) {
    .bot-features-height {
        min-height: 170px;
    }
}

@media (min-width: 1580px) {
    .bot-features-height {
        min-height: 90px;
    }
}